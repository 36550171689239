<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <h2 class="card-title">Application Information</h2>

    <div class="card info-card">
      <el-form ref="application-form" :disabled="disabled" :model="form" >
        <div class="row">
          <el-form-item label="Date" class="field-date">
            <el-date-picker
            v-model="form.date"
            type="date"
            placeholder="Select Date">
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="Start Time" class="field-starttime">
          <el-time-picker
          v-model="form.startTime"
          :format="'HH:mm'"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59'
            }"
            placeholder="Select Start Time">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="End Time" class="field-endtime">
          <el-time-picker
          v-model="form.endTime"
          :format="'HH:mm'"
          :picker-options="{
            selectableRange: '00:00:00 - 23:59:59'
            }"
            placeholder="Select End Time">
          </el-time-picker>
        </el-form-item>
        <el-form-item v-if="viewMode === true && checkIsAdmin() && form.status !== 'accept'"  label="Status" style="max-width: 550px;">
          <el-select v-model="form.status" placeholder="Select the status">
            <el-option label="Pending" value="pending"></el-option>
            <el-option label="Accepted" value="accept"></el-option>
            <el-option label="Rejected" value="reject"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item label="Reason" style="flex-basis: 59%;">
          <el-input
          type="textarea"
          :rows="3"
          v-model="form.reason">
        </el-input>
      </el-form-item>
    </div>
  </el-form>
</div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'AttendanceApplicationForm',
  props:{
    viewMode: Boolean,
    eventName: String,
  },
  data(){
    let disabled = false;

    //Default form value
    let form = {
      date: '',
      startTime: '',
      endTime: '',
      status: '',
      reason: '',
    };

    //If view mode is enabled, disable the form
    if(this.viewMode === true){
      disabled = true;
    };

    return {
      disabled,
      form,
    };
  },
  methods:{
    checkIsAdmin(){
      const currentUser = this.currentUser;
      if(currentUser){
        const isAdmin = Common.checkIsAdmin(currentUser);
        return isAdmin;
      }
      return false;
    },
    startEdit(){
      this.disabled = false;
    },
    save(){
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
  },
  computed: mapState({
    currentUser: state => state.currentUser,
    currentApplication: state => state.hr.currentApplication,
    apiUrl: state => state.apiUrl,
  }),
  watch: {
    'currentApplication': function(){
      this.form = {
        date: dayjs.unix(this.currentApplication.date),
        startTime: dayjs.unix(this.currentApplication.start_time),
        endTime: dayjs.unix(this.currentApplication.end_time),
        status: this.currentApplication.status,
        reason: this.currentApplication.reason,
      };
    }
  }
}
</script>

<style lang="scss">
.info-card{
  padding: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.field-starttime, .field-endtime{
  margin-right: 30px;
}
.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
